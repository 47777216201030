import { DOMAIN_ANCHR } from '@constants/constants';
import { getConfig } from '@lib/prepr/config';
import { fetchDynamicPage } from '@lib/prepr/dynamic';
import BrandPageTemplate from '@templates/BrandPageTemplate/BrandPageTemplate';
import CasesOverviewTemplate from '@templates/CasesOverviewTemplate/CasesOverviewTemplate';
import CaseTemplate from '@templates/CaseTemplate/CaseTemplate';
import CompaniesOverviewTemplate from '@templates/CompaniesOverviewTemplate/CompaniesOverviewTemplate';
import CompanyTemplate from '@templates/CompanyTemplate/CompanyTemplate';
import ContentTemplate from '@templates/ContentTemplate/ContentTemplate';
import EventsOverviewTemplate from '@templates/EventsOverviewTemplate/EventsOverviewTemplate';
import EventTemplate from '@templates/EventTemplate/EventTemplate';
import NewsOverviewTemplate from '@templates/NewsOverviewTemplate/NewsOverviewTemplate';
import NewsTemplate from '@templates/NewsTemplate/NewsTemplate';
import SolutionsOverviewTemplate from '@templates/SolutionsOverviewTemplate/SolutionsOverviewTemplate';
import SolutionTemplate from '@templates/SolutionTemplate/SolutionTemplate';
import ThankYouTemplate from '@templates/ThankYouTemplate/ThankYouTemplate';
import VacanciesOverviewTemplate from '@templates/VacanciesOverviewTemplate/VacanciesOverviewTemplate';
import VacancyApplyTemplate from '@templates/VacancyApplyTemplate/VacancyApplyTemplate';
import VacancyTemplate from '@templates/VacancyTemplate/VacancyTemplate';
import { Locale } from '@type-declarations/locale';
import { Page as PageType } from '@type-declarations/page';
import { NextApiRequest } from 'next';

interface Props {
  page: PageType;
  host: string;
}

function Page({ page, host }: Props) {
  switch (page.typename) {
    case 'NewsOverviewPage':
      return <NewsOverviewTemplate page={page} />;
    case 'NewsPage':
      return <NewsTemplate page={page} />;
    case 'CasesOverviewPage':
      return <CasesOverviewTemplate page={page} />;
    case 'CasePage':
      return <CaseTemplate page={page} />;
    case 'ContentPage':
      return <ContentTemplate page={page} />;
    case 'EventsOverviewPage':
      return <EventsOverviewTemplate page={page} />;
    case 'EventPage':
      return <EventTemplate page={page} />;
    case 'CompaniesOverviewPage':
      return <CompaniesOverviewTemplate page={page} />;
    case 'CompanyPage':
      return <CompanyTemplate page={page} />;
    case 'VacanciesOverviewPage':
      return <VacanciesOverviewTemplate page={page} />;
    case 'VacancyPage':
      return <VacancyTemplate page={page} host={host} />;
    case 'VacancyApplyPage':
      return <VacancyApplyTemplate page={page} />;
    case 'SolutionsOverviewPage':
      return <SolutionsOverviewTemplate page={page} />;
    case 'SolutionPage':
      return <SolutionTemplate page={page} />;
    case 'ThankYouPage':
      return <ThankYouTemplate page={page} />;
    case 'BrandPage':
      return <BrandPageTemplate page={page} />;
    default:
      return null;
  }
}

export async function getServerSideProps({
  req,
  locale,
  params,
  query,
  draftMode: preview = false,
}: {
  req: NextApiRequest;
  locale: Locale;
  params: { slug: string[] };
  draftMode: boolean;
  query: {
    page?: string;
  };
}) {
  const { host = DOMAIN_ANCHR } = req.headers;
  const { slug } = params;
  const [page, config] = await Promise.all([
    fetchDynamicPage({
      host,
      slug,
      locale,
      page: query?.page ? parseInt(query.page, 10) : 1,
      preview,
    }),
    getConfig({ host, locale, preview }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      host,
      locale,
      preview,
      page,
      config,
    },
  };
}

export default Page;
