import { fetchPrepr } from '@lib/prepr/prepr';
import CASE_PAGE_QUERY from '@lib/queries/casePage';
import CASE_PAGES_QUERY from '@lib/queries/casePages';
import CASES_OVERVIEW_PAGE_QUERY from '@lib/queries/casesOverviewPage';
import SOLUTIONS_FILTER_QUERY from '@lib/queries/solutionsFilter';
import { SolutionsFilterResponse } from '@type-declarations/filter';
import { Cases } from '@type-declarations/overviewItems';
import { CasePage, CasesOverviewPage } from '@type-declarations/page';
import { QueryParameters } from '@type-declarations/prepr';

export interface CasesOverviewPageResponse {
  data: {
    CasesOverviewPage: CasesOverviewPage;
  };
}

export interface CasePagesResponse {
  data: {
    CasePages: {
      items: Cases[];
      total: number;
    };
  };
}

export interface CasePageResponse {
  data: {
    CasePage: CasePage;
  };
}

/*
  Cases overviewpage
*/
export async function fetchCasesOverviewPage({
  host,
  locale,
  preview,
  page = 1,
}: QueryParameters) {
  const [casesOverviewPage, casePages, solutionsFilter] = await Promise.all([
    fetchPrepr<CasesOverviewPageResponse>({
      query: CASES_OVERVIEW_PAGE_QUERY,
      variables: { host, locale, preview },
    }),
    fetchPrepr<CasePagesResponse>({
      query: CASE_PAGES_QUERY(),
      variables: {
        page,
        host,
        locale,
        preview,
      },
    }),
    fetchPrepr<SolutionsFilterResponse>({
      query: SOLUTIONS_FILTER_QUERY,
      variables: {
        host,
        locale,
        preview,
      },
    }),
  ]);

  const pageData = casesOverviewPage?.data?.CasesOverviewPage;
  if (!pageData) return null;

  return {
    ...pageData,
    items: casePages?.data?.CasePages.items || [],
    total: casePages?.data?.CasePages.total || 0,
    tags: solutionsFilter?.data?.SolutionPages?.items || [],
  };
}

export async function fetchCasePage({
  slug,
  host,
  locale,
  preview,
}: QueryParameters) {
  if (!slug?.length) return {};
  const res = await fetchPrepr<CasePageResponse>({
    query: CASE_PAGE_QUERY,
    variables: { slug: slug[1], host, locale, preview },
  });
  return res?.data?.CasePage;
}

/* Case pages */
export async function fetchCasePages({
  page,
  host,
  locale,
  preview,
  solutions,
  limit,
}: QueryParameters) {
  const res = await fetchPrepr<CasePagesResponse>({
    query: CASE_PAGES_QUERY(solutions),
    variables: { page, host, locale, preview, solutions, limit },
  });

  return {
    items: res?.data?.CasePages.items || [],
    total: res?.data?.CasePages.total || 0,
  };
}
